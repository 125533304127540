import React from 'react';
import WarningIcon from '@mui/icons-material/Warning';

const Warning = () => (
  <WarningIcon
    sx={{
      color: 'orange',
      height: '14px',
      marginLeft: 1, // Add some spacing between the icon and the text
    }}
  />
);

export default Warning;