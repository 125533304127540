import React from 'react';
import CarrierInfo from './CarrierInfo';
import VerificationResults from './VerificationResults';

import { Container } from '@mui/material';

const CarrierVerificationDetail = ({ carrierData }) => {
  return (
    <Container maxWidth="sm">
      <CarrierInfo carrier={carrierData.carrier} />
      <VerificationResults
        verification={carrierData.verification}
        email={carrierData.query.email}
        dot={carrierData.query.dot} />
    </Container>
  );
};

export default CarrierVerificationDetail;