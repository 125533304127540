import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import VerifiedCheck from './VerifiedCheck';
import NotVerifiedX from './NotVerifiedX';
import Warning from './Warning';

const VerificationResults = ({ verification, email, dot }) => {
  return (
    <Container sx={{
      backgroundColor: '#EFF4F4',
      padding:2,
      marginTop: 2,
      border: '1px solid #D2D3D5',
      borderRadius: 2,
  }}>

      <Typography variant="overline">Parade Network Verification</Typography>
      {verification? (
        <div>
          <Box>
            <p>
              Is this carrier DOT # known to the Parade Network?{" "}
              {verification.knownCarrier ? (
                <><strong>Yes</strong> <VerifiedCheck /></>
              ) : <><strong>No</strong> <NotVerifiedX /></>}
            </p>
            <Box>
              <Typography variant="body2"># brokers this carrier has covered a load for on Parade in the past:</Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography>30 Days: {verification.aggNumBrokers.days30}</Typography>
                <Typography>60 Days: {verification.aggNumBrokers.days60}</Typography>
                <Typography>90 Days: {verification.aggNumBrokers.days90}</Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="body2"># brokers this carrier has covered a load for on Parade, using this email, in the past:</Typography>
              <Box display="flex" flexDirection="row" justifyContent="space-between">
                <Typography>30 Days: {verification.aggNumBrokersEmails.days30}</Typography>
                <Typography>60 Days: {verification.aggNumBrokersEmails.days60}</Typography>
                <Typography>90 Days: {verification.aggNumBrokersEmails.days90}</Typography>
              </Box>
            </Box>
          </Box>
          <Box>
            <p>
            Is <strong>{email}</strong> known to be fraudulent? {" "}
              {verification.fraudulentEmail === null ? (
                <><strong> Unknown: System Outage</strong> <Warning /></>
              ) : verification.fraudulentEmail ? (
                <><strong>Yes</strong> <NotVerifiedX /></>
              ) : <><strong>No</strong> <VerifiedCheck /></>}
            </p>
            <p>
            Is <strong>{email}</strong> known to the Parade Network? {" "}
              {verification.knownEmail ? (
                <><strong>Yes</strong> <VerifiedCheck /></>
              ) : <><strong>No</strong> <NotVerifiedX /></>}
            </p>
            <p>
            Has <strong>{email}</strong> been used with <strong>DOT # {dot}</strong> before? {" "}
              {verification.associatedWithDOT ? (
                <><strong>Yes</strong> <VerifiedCheck /></>
              ) : <><strong>No</strong> <NotVerifiedX /></>}
            </p>
            <Typography>Number of DOTs we have seen this email associated with: <strong>{verification.numDOTAssociated}</strong></Typography>
          </Box>
        </div>
      ) : (
        <div><Warning />Error: Invalid DOT # or unable to access network.</div>
      )
      }
    </Container>
  );
};

export default VerificationResults;