import React from 'react';
import { Typography, Container, Box } from '@mui/material';

const Disclaimer = () => (
    <Container maxWidth='sm' minWidth='sm' sx={{
        padding:2,
        marginTop: 2,
    }}>
              <Box
        component="div"
        sx={{
          textAlign: "justify",
        }}
      >
        <Typography variant='caption' color='textSecondary' className='justified-text' sx={{
          textAlign: "justify",
          textJustify: "inter-word",
        }}>
            Legal Disclaimer:

            By using Parade's Carrier Verification App ("the App"), you acknowledge and agree that the information, data, and content provided through the App is presented "as is" and without any warranties or guarantees, either express or implied. Parade makes no representations or warranties as to the accuracy, reliability, completeness, or timeliness of the data provided. Parade disclaims all liability for any errors, omissions, or inaccuracies in the information presented through the App.

            You further acknowledge and agree that your use of the App and any decisions or actions taken based on the information and data provided are at your sole risk and discretion. Parade shall not be held liable for any damages, losses, or consequences arising from or in connection with your use of the App, or any decisions or actions taken based on the information and data provided therein.

            By using the App, you hereby release and hold Parade, its affiliates, officers, employees, and agents harmless from any and all claims, demands, damages, losses, or liabilities, of any kind or nature whatsoever, arising from or in connection with your use of the App or any reliance upon the information and data provided therein.
        </Typography>
        </Box>
    </Container>
);

export default Disclaimer;