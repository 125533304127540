
import React, { useMemo } from 'react';

import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

import { Box } from '@mui/material';
import { auth }from './firebase';

const FirebaseUI = () => {
  const uiConfig = useMemo(() => ({
    signInFlow: 'redirect',
    signInOptions: [
      { // email link
        provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
        signInMethod: firebase.auth.EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD
      },
      // firebase.auth.GoogleAuthProvider.PROVIDER_ID, // Google account
    ],
    callbacks: {
      signInSuccessWithAuthResult: () => false,
    },  
  }), []);

  return (
    <Box mt={2}>
      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
    </Box>
  );
};

export default FirebaseUI;