import React from 'react';
import { Typography, Box, Link } from '@mui/material';


const Logo = () => (
  <Box marginTop={1}
       marginBottom={1}
       sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
    <Box>
      <Typography variant="h5"><strong>carriers.fyi</strong></Typography>
    </Box>
    <Box marginLeft={2}>
      <Link href="https://www.parade.ai/" target="_blank" rel="noopener noreferrer" color="inherit" underline="hover">
        <Typography variant="subtitle2">Powered by <strong>Parade</strong></Typography>
      </Link>
    </Box>
  </Box>
);

export default Logo;