import React, { useContext } from 'react';
import { Button } from '@mui/material';
import { auth } from '../firebase';
import { AuthContext } from '../context/AuthContext';

const SignOutButton = () => {
  const { isLoggedIn } = useContext(AuthContext)
  if (!isLoggedIn) return null;

  return (
    <Button color='inherit' variant='text' onClick={() => auth.signOut()}>Sign out</Button>
  );
}

export default SignOutButton