import { PopupButton } from '@typeform/embed-react'
import { Container } from '@mui/material';
import './Typeform.css';

const ReportCarrier = () => {
  return (
    <PopupButton id="dGD6og1M" className="feedback-button">
        Report a carrier
    </PopupButton>
  )
}

export default ReportCarrier;