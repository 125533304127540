import React from 'react';
import { Container, Typography, Grid, Box } from '@mui/material';
import VerifiedCheck from './VerifiedCheck';
import Warning from './Warning';


const CarrierInfo = ({ carrier }) => {
  return (
    <Container sx={{
        backgroundColor: '#EEF7FF',
        padding:2,
        marginTop: 2,
        border: '1px solid #008EFF',
        borderRadius: 2,
    }}>
      <Box sx={{ 
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <Typography variant="overline">Carrier Information</Typography>
        <Typography variant="overline" color="#008EFF"><strong>FMCSA</strong></Typography>
      </Box>
      {carrier ? (
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Typography variant="body2">Legal Name: {carrier.legalName}</Typography>
            <Typography variant="body2">DBA Name: {carrier.dbaName}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body2">DOT: {carrier.dot}</Typography>
            <Typography variant="body2">MC: {carrier.mc}</Typography>
            <Typography variant="body2">Broker Authority: {carrier.isBroker ? (
              <><strong>Yes</strong> <Warning /></>
            ) : <><strong>None</strong> <VerifiedCheck /></>}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">Physical Address</Typography>
            <Typography variant="body2">{carrier.phyStreet} <br/>{carrier.phyCity}, {carrier.phyState}   {carrier.phyZip}  </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">Mailing Address</Typography>
            <Typography variant="body2">{carrier.maiStreet} <br/>{carrier.maiCity}, {carrier.maiState}   {carrier.maiZip}  </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="caption">Phone Numbers</Typography>
            {carrier.telNum && <Typography variant="body2">Tel: {carrier.telNum}</Typography>}
            {carrier.cellNum && <Typography variant="body2">Cell: {carrier.cellNum}</Typography>}
            {carrier.faxNum && <Typography variant="body2">Fax: {carrier.faxNum}</Typography>}
          </Grid>
        </Grid>
      ) : (
        <div><Warning />Could not find the requested DOT #</div>
      )}
    </Container>
  );
};

export default CarrierInfo;