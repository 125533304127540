import React, { useContext } from 'react';
import CarrierLookupInputForm from './components/CarrierLookupInputForm';
import CarrierVerificationDetail from './components/CarrierVerificationDetail';
import Disclaimer from './components/Disclaimer';
import Navbar from './components/Navbar';
import Feedback from './components/Feedback';
import ReportCarrier from './components/ReportCarrier';
import { AuthContext } from './context/AuthContext';
import FirebaseUI from './FirebaseUI';
import { Container, Typography, Box } from '@mui/material';


const App = () => {
  const { isLoggedIn } = useContext(AuthContext);
  const [carrierData, setCarrierData] = React.useState(null);

  const handleVerificationResults = (data) => {
    setCarrierData(data);
  };

  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Navbar />
      {isLoggedIn ? (
        <>
          <CarrierLookupInputForm onVerificationResults={handleVerificationResults} />
          {carrierData && <CarrierVerificationDetail carrierData={carrierData} />}
          <Container maxWidth='sm' minWidth='sm' sx={{
              padding:1,
              marginTop: 1,
          }}>
            <Feedback />
            <ReportCarrier />
          </Container>
          <Disclaimer />
        </>
      ) : (
        <FirebaseUI />
      )}
    </div>
  );
};

export default App;