import React, { useContext } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import LinearProgress from '@mui/material/LinearProgress';
import { Typography, Button, TextField, Container, Box, RadioGroup, FormControlLabel, Radio, FormControl, FormLabel } from '@mui/material';
import {formatPhoneNumber} from '../utils';
import { AuthContext } from '../context/AuthContext';
import { trackQuery } from '../services/Tracker';

const baseUrl = "https://api.carriers.fyi";
const headers = {
  Authorization: `Token ${process.env.REACT_APP_API_AUTH_TOKEN}`
};

const CarrierLookupInputForm = ({ onVerificationResults }) => {
    const { isLoggedIn } = useContext(AuthContext);
    const [email, setEmail] = React.useState('');
    const [idNumber, setIdNumber] = React.useState('');
    const [idType, setIdType] = React.useState('dot_number');
    const [isLoading, setLoading] = React.useState(false);

    const fetchCarrier = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/carrier_info/${idType}/${idNumber}`, { headers });
        if (!response.ok) {
          throw new Error("Error fetching carrier info");
        }
        const data = await response.json();
        return {
          legalName: data.name,
          dot: data.dot_number,
          mc: data.mc_number,
          isBroker: data.is_broker,
          phyStreet: data.phy_str,
          phyCity: data.phy_city,
          phyState: data.phy_st,
          phyZip: data.phy_zip,
          maiStreet: data.mai_str,
          maiCity: data.mai_city,
          maiState: data.mai_st,
          maiZip: data.mai_zip,
          telNum: formatPhoneNumber(data.tel_num),
          cellNum: formatPhoneNumber(data.cell_num),
          faxNum: formatPhoneNumber(data.fax_num),
        };
      } catch (error) {
        console.error(error);
        return null;
      }
    };

    const fetchVerification = async () => {
      try {
        const response = await fetch(
          `${baseUrl}/api/verification?id_value=${idNumber}&id_type=${idType}&email_address=${email}`,
          { headers }
        );
        if (!response.ok) {
            throw new Error("Error fetching verification info");
        }
        const data = await response.json();
        return {
          aggNumBrokers: { 
            days30: data.n_brokers_30_days, 
            days60: data.n_brokers_60_days, 
            days90: data.n_brokers_90_days 
          },
          aggNumBrokersEmails: { 
            days30: data.n_brokers_30_days__email, 
            days60: data.n_brokers_60_days__email, 
            days90: data.n_brokers_90_days__email 
          },
          numDOTAssociated: data.num_dot_associated,
          knownCarrier: data.carrier_known,
          knownEmail: data.email_known,
          associatedWithDOT: data.associated_with_dot,
          fraudulentEmail: data.has_user_reported_fraud,
        };
      } catch (error) {
        console.error("Error fetching verification info");
        return null;
      }
    };

    const handleSubmit = async () => {
      // clear search results
      onVerificationResults(null);
      setLoading(true);
    
      // Make API call
      try {
        const carrierDataPromise = fetchCarrier();
        const verificationDataPromise = fetchVerification();
        trackQuery(email, idNumber, idType);
        const [carrierData, verificationData] = await Promise.all([carrierDataPromise, verificationDataPromise]);
        onVerificationResults({
          query: {
            idType: idType,
            idNumber: idNumber,
            email: email,
          },
          carrier: carrierData,
          verification: verificationData,
        });
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    return (
      <Container minWidth="sm" maxWidth="sm">
        <Box marginTop={2} marginBottom={2}>
            <Typography variant="overline" align="center">
                Carrier Verification Lookup
            </Typography>
        </Box>
        <Box display="flex" flexDirection="column" alignItems="center">
          <FormControl component="fieldset" sx={{ alignSelf: 'flex-start' }}>
            <FormLabel component="legend">Select Identifier Type</FormLabel>
            <RadioGroup row value={idType} onChange={(e) => setIdType(e.target.value)}>
                <FormControlLabel value="dot_number" control={<Radio />} label="DOT #" />
                <FormControlLabel value="mc_number" control={<Radio />} label="MC #" />
            </RadioGroup>
          </FormControl>

          <TextField
            label={idType === 'dot_number' ? 'DOT #' : 'MC #'}
            value={idNumber}
            required
            onChange={(e) => setIdNumber(e.target.value)}
            sx={{ width: '100%', marginBottom: 2 }}
            variant="outlined"
            size="small"
          />

          <TextField
            label="Email"
            type="email"
            value={email}
            required
            onChange={(e) => setEmail(e.target.value)}
            sx={{ width: '100%', marginBottom: 2 }}
            variant="outlined"
            size="small"
          />

          <Button
            variant="contained"
            color="primary"
            disabled={!isLoggedIn || isLoading}
            fullWidth
            onClick={handleSubmit}
            startIcon={<SearchIcon />}
          >
            {isLoggedIn ? 'Search' : 'Sign in first'}
          </Button>
        </Box>
        <Box marginTop={2}>
            {isLoading && <LinearProgress />}
        </Box>
      </Container>
    );
  };
  
  export default CarrierLookupInputForm;