import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';


const VerifiedCheck = () => (
    <CheckCircleIcon
    sx={{
      color: '#00ffbd',  // 00ffbd
      height: '14px',
      marginLeft: 1, // Add some spacing between the icon and the text
    }} />
);

export default VerifiedCheck;