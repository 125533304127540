import React from 'react';
import CancelIcon from '@mui/icons-material/Cancel';

const NotVerifiedX = () => (
  <CancelIcon
    sx={{
      color: 'red',
      height: '14px',
      marginLeft: 1, // Add some spacing between the icon and the text
    }}
  />
);

export default NotVerifiedX;