import { logEvent } from "firebase/analytics";
import { analytics } from "../firebase";

export const trackQuery = (email, id_number, id_type) => {
  logEvent(analytics, 'query', {
    email,
    id_number,
    id_type,
  });
}
