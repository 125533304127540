import { PopupButton } from '@typeform/embed-react'
import { Container } from '@mui/material';
import './Typeform.css';

const Feedback = () => {
  return (
    <PopupButton id="BAZJLyKW" className="feedback-button">
        Leave feedback
    </PopupButton>
  )
}

export default Feedback;